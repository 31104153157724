<template>
  <div class="content">
    <!-- 表格内容 -->
    <commonTitle></commonTitle>
    <div class="table_content" style="width: 1000px;">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        v-loading="loading"
        element-loading-text="修改中,请稍后..."
      >
        <el-form-item label="姓名" prop="ownerName">
          <el-input type="text" v-model="ruleForm.ownerName" placeholder="请输入业主姓名"></el-input>
        </el-form-item>
        <el-form-item label="身份证号码" prop="idCard">
          <el-input type="text" v-model="ruleForm.idCard" placeholder="请输入业主身份证号码"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input type="text" v-model="ruleForm.phone" placeholder="请输入业主联系电话"></el-input>
        </el-form-item>
        <el-form-item label="邮件">
          <el-input type="text" v-model="ruleForm.mail" placeholder="请输入业主电子邮件"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="submitForm('ruleForm')">立即修改</el-button>
          <!--                    <el-button @click="resetForm('ruleForm')">返回</el-button>-->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import commonTitle from "../table/commonTitle";
export default {
  name: "ownerInfo",
  components: {
    commonTitle
  },
  data() {
    return {
      ruleForm: {
        ownerId: "", //业主id
        password: "", //密码
        username: "", //用户名
        ownerName: "", //姓名
        idCard: "", //身份证号码
        phone: "", //联系电话
        mail: "" //电子邮件
      },
      rules: {
        ownerName: [
          { required: true, message: "请输入业主姓名", trigger: "change" }
        ],
        idCard: [
          { required: true, message: "请输入业主身份证号码", trigger: "change" }
        ],
        phone: [
          { required: true, message: "请输入业主联系电话", trigger: "change" }
        ],
        mail: [
          { required: true, message: "请输入业主电子邮件", trigger: "change" }
        ]
      },
      url: this.$Config.base_server,
      loading: false
    };
  },
  mounted() {
    this.common();
  },
  methods: {
    common() {
      //初始化数据
      var _this = this; //声明一个变量指向Vue实例this，保证作用域一致
      //查询当前用户信息
      $.ajax({
        url:
          _this.url +
          "/api-public/opeOwner/findById/" +
          JSON.parse(localStorage.getItem("user")).id +
          "?access_token=" +
          localStorage.getItem("token"),
        type: "get",
        success: function(res) {
          _this.ruleForm = res;
        }
      });
    },
    submitForm(formName) {
      this.$confirm("确定要修改个人信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          var _this = this;
          _this.loading = true;
          //立即创建按钮
          this.$refs[formName].validate(function(valid) {
            if (valid) {
              $.ajax({
                url:
                  _this.url +
                  "/api-public/opeOwner/update?access_token=" +
                  localStorage.getItem("token"),
                type: "post",
                data: JSON.stringify(_this.ruleForm),
                contentType: "application/json",
                success: function(result) {
                  if (result.resp_code == 0) {
                    //修改成功
                    _this.loading = false;
                    _this.$message({
                      message: result.resp_msg,
                      type: "success",
                      dangerouslyUseHTMLString: true,
                      duration: 1000
                      // onClose: function () {
                      //     //返回上层路由
                      //     _this.$router.go(-1);
                      // }
                    });
                  } else {
                    //修改失败
                    _this.loading = false;
                    _this.$message({
                      message: result.resp_msg,
                      dangerouslyUseHTMLString: true,
                      type: "warning"
                    });
                  }
                }
              });
            } else {
              _this.loading = false;
              console.log("error submit!!");
              return false;
            }
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    resetForm(formName) {
      //返回上一层路由
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.content {
  padding: 20px 111px;
  background: #fff;
}

.el-form {
  width: 600px;
  margin-left: 60px;
}
</style>